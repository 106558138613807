import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component({
    template: require('./station-state-info.html')
})
export class StationStateInfo extends Vue {

    @Prop()
    private severity: string;

    @Prop()
    private title: string;

    @Prop()
    private icon: string;
}
