import {Moment} from 'moment';
import * as moment from 'moment';

export class StationStateDtoMapper {

    public mapDto(dto: IStationStateDto): IStationState {
        return {
            networkAlarmInput: dto.networkAlarmInput != null ? this.mapSubsystemState(dto.networkAlarmInput) : null,
            radioAlarmInput: dto.radioAlarmInput != null ? this.mapSubsystemState(dto.radioAlarmInput) : null,
            alarmSuppressionRequested: dto.alarmSuppressionRequested != null ? this.mapSubsystemState(dto.alarmSuppressionRequested) : null,
            alarmResetRequested: dto.alarmResetRequested != null ? this.mapSubsystemState(dto.alarmResetRequested) : null,
            forceCloseRequested: dto.forceCloseRequested != null ? this.mapSubsystemState(dto.forceCloseRequested) : null,
            alarmState: dto.alarmState != null ? this.mapSubsystemState(dto.alarmState) : null,
            alarmPendingState: dto.alarmPendingState != null ? this.mapSubsystemState(dto.alarmPendingState) : null,
            suppressionState: dto.suppressionState != null ? this.mapSubsystemState(dto.suppressionState) : null,
            forceCloseState: dto.forceCloseState != null ? this.mapSubsystemState(dto.forceCloseState) : null,
        };
    }

    private mapSubsystemState(dto: ISubsystemStateDto): ISubsystemState {
        return {
            timestamp: moment(dto.timestamp),
            state: dto.state,
            requestedBy: dto.requestedBy
        };
    }
}

export interface IStationState {
    networkAlarmInput: ISubsystemState | null;
    radioAlarmInput: ISubsystemState | null;
    alarmSuppressionRequested: ISubsystemState | null;
    alarmResetRequested: ISubsystemState | null;
    forceCloseRequested: ISubsystemState | null;
    alarmState: ISubsystemState | null;
    alarmPendingState: ISubsystemState | null;
    suppressionState: ISubsystemState | null;
    forceCloseState: ISubsystemState | null;
}


export interface IStationStateDto {
    networkAlarmInput: ISubsystemStateDto | null;
    radioAlarmInput: ISubsystemStateDto | null;
    alarmSuppressionRequested: ISubsystemStateDto | null;
    alarmResetRequested: ISubsystemStateDto | null;
    forceCloseRequested: ISubsystemStateDto | null;
    alarmState: ISubsystemStateDto | null;
    alarmPendingState: ISubsystemStateDto | null;
    suppressionState: ISubsystemStateDto | null;
    forceCloseState: ISubsystemStateDto | null;
}

export interface ISubsystemState {
    timestamp: Moment;
    state: boolean;
    requestedBy: string;
}

export interface ISubsystemStateDto {
    timestamp: string;
    state: boolean;
    requestedBy: string;
}
