import axios, {AxiosInstance} from 'axios';

export class ApiClient {

    private client: AxiosInstance;

    constructor(baseUrl: string, apiToken: string) {

        this.client = axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `GeoHub ${apiToken}`
            }
        });
    }

    public requestAlarmSuppression(suppressionActive: boolean): Promise<void> {
        return this.client.post('api/v1/carrerabach/suppression', {suppress: suppressionActive})
            .then(r => r.data);
    }

    public requestCloseTrack(close: boolean): Promise<void> {
        return this.client.post('api/v1/carrerabach/closetrack', {close: close})
            .then(r => r.data);
    }

    public requestAlarmReset(reset: boolean): Promise<void> {
        return this.client.post('api/v1/carrerabach/reset', {reset: reset})
            .then(r => r.data);
    }

    public getPermissions(): Promise<string[]> {
        return this.client.get('api/v1/permissions', {}).then(r => r.data.permissions);
    }
}

