import Component from 'vue-class-component';
import Vue from 'vue';
import {Inject, Prop} from 'vue-property-decorator';
import {StationStateTracker} from './station-state-tracker';
import {StationStateInfo} from './station-state-info';
import {IStationState} from './station-state-dto-mapper';
import {ApiClient} from "./api-client";

@Component({
    template: require('./alarm-indicator.html'),
    components: {StationStateInfo}
})
export class AlarmIndicator extends Vue {

    @Inject()
    private stationStateTracker: StationStateTracker;

    @Inject()
    private apiClient: ApiClient;

    private stationState: IStationState | null = null;

    private postingTrackChange = false;

    @Prop()
    private alarmSuppressionPanelEnabled: boolean;

    constructor() {
        super();

        this.stationStateTracker.registerAlarmStateCallback(s => {
            this.updateAlarmState(s);
        });
    }

    private updateAlarmState(alarmState: IStationState) {
        this.stationState = alarmState;
    }

    private openTrack() {
        this.postingTrackChange = true;
        this.apiClient.requestCloseTrack(false).then(() => this.requestCompleted());
    }

    private resetAlarm() {
        this.postingTrackChange = true;
        this.apiClient.requestAlarmReset(true).then( () => this.requestCompleted());
    }


    private requestCompleted() {
        this.postingTrackChange = false;
    }
}
