import Component from 'vue-class-component';
import Vue from 'vue';
import {IStationState} from "./station-state-dto-mapper";
import {StationStateTracker} from "./station-state-tracker";
import {Inject} from "vue-property-decorator";
import {StationStateInfo} from "./station-state-info";
import {ApiClient} from "./api-client";


@Component({
    template: require('./close-track-panel.html'),
    components: {
        StationStateInfo
    }
})

export class CloseTrackPanel extends Vue {

    private stationState: IStationState | null = null;

    @Inject()
    private stationStateTracker: StationStateTracker;

    private closingTrack: boolean = false;

    @Inject()
    private apiClient: ApiClient;

    constructor() {
        super();

        this.stationStateTracker.registerAlarmStateCallback(s => {
            this.updateStationState(s);
        });
    }


    private updateStationState(stationState: IStationState) {
        this.stationState = stationState;
    }

    private closeTrack() {
        this.closingTrack = true;
        return this.apiClient.requestCloseTrack(true).then(() => this.requestClompleted());
    }

    private requestClompleted() {
        this.closingTrack = false;
    }
}
