import './app.scss';

import Vue from "vue";
import Component from "vue-class-component";
import {Inject, Provide} from "vue-property-decorator";
import {IAppConfig} from '../app-config';

const queryString = require('query-string');

import {StationStateTracker} from './station-state-tracker';
import {AlarmIndicator} from './alarm-indicator';
import {ApiClient} from './api-client';
import {ConnectionTracker} from './connection-tracker';
import {ConnectionStatePanel} from './connection-state-panel';
import {StationStateDtoMapper} from './station-state-dto-mapper';
import {StationStateLogsPanel} from './station-state-logs-panel';
import {AlarmSuppressionPanel} from './alarm-suppression-panel';
import {StationStateLogsDtoMapper} from './station-state-logs-dto-mapper';
import {CloseTrackPanel} from "./close-track-panel";

@Component({
    template: require('./app.html'),
    components: {
        AlarmIndicator,
        ConnectionStatePanel,
        StationStateLogsPanel,
        AlarmSuppressionPanel,
        CloseTrackPanel
    }
})
export default class App extends Vue {

    @Inject()
    private appConfig: IAppConfig;

    @Provide()
    private apiClient: ApiClient;

    @Provide()
    private connectionTracker: ConnectionTracker;

    @Provide()
    private stationStateTracker: StationStateTracker;

    @Provide()
    private stationStateLogsDtoMapper: StationStateLogsDtoMapper;

    private alarmSuppressionPanelEnabled = false;

    constructor() {
        super();
        let query = queryString.parse(location.search);

        let alarmStateDtoMapper = new StationStateDtoMapper();

        this.stationStateLogsDtoMapper = new StationStateLogsDtoMapper(alarmStateDtoMapper);

        this.connectionTracker = ConnectionTracker.Create(this.appConfig.stationLinkBaseUrl, query.apiToken);
        this.stationStateTracker = new StationStateTracker(this.connectionTracker, alarmStateDtoMapper, this.stationStateLogsDtoMapper);
        this.apiClient = new ApiClient(this.appConfig.stationLinkBaseUrl, query.apiToken);

        this.apiClient.getPermissions().then(p => this.enablePermissionBasedFeatures(p));
    }

    private enablePermissionBasedFeatures(permissions: string[]) {
        this.alarmSuppressionPanelEnabled = permissions.indexOf('CarrerabachSuppressAlarm') !== -1;
    }
}

