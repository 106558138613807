import Vue from 'vue';
import Component from 'vue-class-component';
import {Inject} from 'vue-property-decorator';
import {StationStateTracker} from './station-state-tracker';
import {ApiClient} from './api-client';
import {IStationState} from './station-state-dto-mapper';
import {StationStateInfo} from "./station-state-info";

@Component({
    template: require('./alarm-suppression-panel.html'),
    components: {
        StationStateInfo
    }
})
export class AlarmSuppressionPanel extends Vue {

    @Inject()
    private stationStateTracker: StationStateTracker;

    @Inject()
    private apiClient: ApiClient;

    private stationState: IStationState | null = null;

    private postingSuppressionChange = false;

    constructor() {
        super();

        this.stationStateTracker.registerAlarmStateCallback(s => {
            this.updateAlarmState(s);
        });
    }

    private get suppressionPossible() {
        return true;
    }

    private updateAlarmState(stationState: IStationState) {
        this.stationState = stationState;
    }

    private stopSuppression() {
        this.postingSuppressionChange = true;
        return this.apiClient.requestAlarmSuppression(false).then(() => this.suppressionRequestCompleted());
    }

    private activateSuppression() {
        this.postingSuppressionChange = true;
        return this.apiClient.requestAlarmSuppression(true).then(() => this.suppressionRequestCompleted());
    }

    private suppressionRequestCompleted() {
        this.postingSuppressionChange = false;
    }

}
